import {
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from '@/utils/themes';

export default {
    apiCall(state, isCallApi) {
        state.isCallApi = isCallApi
    },
    countryChange(state, isCountryChange) {
        state.isCountryChange = isCountryChange
    },
    historyBack(state, isHistoryBack) {
        state.isHistoryBack = [...state.isHistoryBack, isHistoryBack]
    },
    breadcrumb(state, isBreadcrumb) {
        state.isBreadcrumb = [isBreadcrumb]
    },
    toggleMenuSidebar(state) {
        state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
    },
    toggleControlSidebar(state) {
        state.controlSidebarCollapsed = !state.controlSidebarCollapsed;
    },
    toggleDarkMode(state) {
        state.darkModeSelected = !state.darkModeSelected;
    },
    // removeDarkMode(state) {
    //     state.darkModeSelected = false;
    // },
    toggleResetSeting(state) {
        state.actionReseted = true;
    },
    toggleNavbarFixed(state) {
        state.navbarFixed = !state.navbarFixed;
    },
    toggleNoBorder(state) {
        state.noBorderSelected = !state.noBorderSelected;
    },
    setNavbarVariant (state, value) {
        state.navbarVariant = value;
    },
    setSidebarSkin (state, value) {
        state.sidebarSkin = value;
    },
    setActiveTab (state, value) {
        state.activeTab = value;
    },
    setNotReadMsg (state, value) {
        state.notReadMsg = value;
    },
    setReceiptId (state, value) {
        state.receiptId = value;
    },
    setIsVip (state, value) {
        state.isVip = value;
    },
    setIsCheckR (state, value) {
        state.isCheckR = value;
    },
    setMenuChange (state, value) {
        state.isMenuChange = value;
    },
};
