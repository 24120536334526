const client = {
  path: "/indexHome",
  name: "indexHome",
  component: () => import("../views/client/Index.vue"),
  meta: {
    requiresUnauth: true,
  },
  children: [
    {
      path: "/homeIndex",
      name: "homeIndex",
      component: () => import("../views/client/home/Home.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/inform',
      name: 'Inform',
      component: () =>
        import('../views/client/user/UserInform.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
      }
    },
    {
      path: "/roulette",
      name: "roulette",
      component: () => import("../views/client/roulte/ViRoultte.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/scans",
      name: "scans",
      component: () => import("../views/client/qr_code/QRCode.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products",
      name: "products",
      component: () => import("../views/client/product/Product.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("../views/client/news/NewsList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/details",
      name: "details",
      component: () => import("../views/client/news/NewsDetails.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/points",
      name: "points",
      component: () => import("../views/client/save_point/SavePoint.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sell",
      name: "sell",
      component: () => import("../views/client/sell/Sell.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/gift",
      name: "gift",
      component: () => import("../views/client/gift/Gift.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/rules",
      name: "rules",
      component: () => import("../views/client/rules/Rules.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/academy",
      name: "academy",
      component: () => import("../views/client/academy/Academy.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/registers",
      name: "registers",
      component: () => import("../views/client/user/RegisterForm.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/password",
      name: "password",
      component: () => import("../views/client/user/ChangePassword.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/passwordOpt",
      name: "passwordOpt",
      component: () => import("../views/client/user/ChangePasswordOpt.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/setting",
      name: "setting",
      component: () => import("../views/client/setting/Setting.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/opt",
      name: "opt",
      component: () => import("../views/client/user/OptCode.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/opts",
      name: "opts",
      component: () => import("../views/client/user/OptCodePass.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default client;
