import mutations from './mutations';
import actions from './actions';
import getters from './getters';
const themeStore = {
    state: {
        bgThemeMode: false,
    },
    mutations,
    actions,
    getters
};

export default themeStore;
