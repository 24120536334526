import { RouteRecordRaw, Router } from "vue-router";
import Index from "../views/dg6789admin6868/Index.vue";
import client from "./client";
const routesAdmin: Array<RouteRecordRaw> = [
  // routesExample,
  {
    path: "/",
    name: "",
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ "/admin": "Home" }],
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/admin/dashboard/Dashboard.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "dashboard", status: false },
          ],
        },
      },
      {
        path: "/salons",
        name: "salons",
        component: () => import("../views/admin/salon/Profile.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Salon", status: false },
          ],
        },
      },
      {
        path: "/salons-create",
        name: "Salon Create",
        component: () => import("../views/admin/salon/SalonCreate.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/salon", name: "Salon", status: false },
            { url: "/salon-create", name: "Salon Create", status: false },
          ],
        },
      },
      {
        path: "/salons-edit",
        name: "Salon Edit",
        component: () => import("../views/admin/salon/SalonEdit.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/salon", name: "Salon", status: false },
            { url: "/salon-edit", name: "Salon Edit", status: false },
          ],
        },
      },
      {
        path: "/salons-detail",
        name: "salons-detail",
        component: () => import("../views/admin/salon/SalonDetail.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/salon", name: "Salon", status: false },
            { url: "/salon-edit", name: "Salon Detail", status: false },
          ],
        },
      },
      {
        path: "/booking",
        name: "Booking",
        component: () => import("../views/admin/booking/BookingList.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/booking", name: "Booking", status: false },
          ],
        },
      },
      {
        path: "/booking-create",
        name: "Booking Create",
        component: () => import("../views/admin/booking/BookingCreate.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/booking", name: "Booking", status: false },
            {
              url: "/booking-create",
              name: "Booking Create",
              status: false,
            },
          ],
        },
      },
      {
        path: "/service",
        name: "Service",
        component: () => import("../views/admin/service/ServiceList.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Service", status: false },
          ],
        },
      },
      {
        path: "/service-create",
        name: "Service Create",
        component: () => import("../views/admin/service/ServiceCreate.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/service", name: "Service", status: true },
            { url: "", name: "Service Create", status: false },
          ],
        },
      },
      {
        path: "/services-edit",
        name: "Service Edit",
        component: () => import("../views/admin/service/ServiceEdit.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/service", name: "Service", status: true },
            { url: "", name: "Service Edit", status: false },
          ],
        },
      },
      {
        path: "/services-detail",
        name: "Services Detail",
        component: () => import("../views/admin/service/ServiceDetail.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "/service", name: "Service", status: true },
            { url: "", name: "Service Detail", status: false },
          ],
        },
      },
      {
        path: "/salon/sms",
        name: "salonSms",
        component: () => import("../views/admin/sms/SalonSms.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Apointment SMS Count", status: false },
          ],
        },
      },
      {
        path: "/survey-customer",
        name: "Survey Customer",
        component: () => import("../views/admin/survey/SurveyCustomer.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Survey Customer", status: false },
          ],
        },
      },
      {
        path: "/sms-history",
        name: "sms-history",
        component: () => import("../views/admin/sms/SmsHistory.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Sms History", status: false },
          ],
        },
      },
      {
        path: "/sms-send",
        name: "sms-send",
        component: () => import("../views/admin/sms/SmsSend.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Sms Send", status: false },
          ],
        },
      },
      {
        path: "/sms-setting",
        name: "Sms Setting",
        component: () => import("../views/admin/sms/SmsSetting.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Sms Setting", status: false },
          ],
        },
      },
      {
        path: "/sms-template",
        name: "Sms Template",
        component: () => import("../views/admin/sms/SmsTemplate.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Sms Template", status: false },
          ],
        },
      },
      {
        path: "/salon-sms",
        name: "salon-sms",
        component: () => import("../views/admin/sms/SalonSms.vue"),
        meta: {
          requiresAuth: false,
          breadcrumb: [
            { url: "/", name: "Home", status: true },
            { url: "", name: "Salon detail", status: false },
          ],
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/client/user/Login.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/admin/sample/demo.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
  client,
  {
    path: "/:pathMatch(.*)*",
    name: "*",
    component: () => import("../views/404.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
];
export default routesAdmin;
