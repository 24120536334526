<template>
    <el-dropdown>
        <span class="el-dropdown-link">
            <country-flag :country="lang" size='normal' />
        </span>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="onChange('us')">
                    <country-flag country="us" size='normal' /><span class="lable">{{ $t('message.usa') }}</span>
                </el-dropdown-item>
                <el-dropdown-item @click="onChange('vn')">
                    <country-flag country="vn" size='normal' /><span class="lable">{{ $t('message.vietnam') }}</span>
                </el-dropdown-item>

            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
import CountryFlag from 'vue-country-flag-next'
import { useCookies } from "vue3-cookies"
import { LANGUAGE, US } from '@/const/language'
import store from '@/store/index'
import { createApp } from 'vue'
import { useI18n } from "vue-i18n"
import serviceData from '@/services/servicesApi'
import VueI18n from "vue-i18n"
import commons from "@/utils/Common";
export default {
    setup() {
        const { cookies } = useCookies()
        const app = createApp({})
        // let lang_cookie = cookies.get(LANGUAGE)
        // if (lang_cookie == null || lang_cookie == undefined) {
        //     cookies.set(LANGUAGE, US)
        // } else {
        //     cookies.set(LANGUAGE, lang_cookie)
        // }
        let lang_cookie = commons.getStoreLanguage()
        if (lang_cookie == null || lang_cookie == undefined) {
            commons.setStoreLanguage(US)
        } else {
            commons.setStoreLanguage(lang_cookie)
        }
        const { t } = useI18n()
        return { cookies, app, serviceData, t }
    },
    data() {
        return {
            lang: US,
        }
    },
    components: {
        CountryFlag,
    },
    mounted() {
        commons.getStoreLanguage()
        this.$i18n.locale = commons.getStoreLanguage()
        this.lang = commons.getStoreLanguage()
        // this.$i18n.locale = this.cookies.get(LANGUAGE)
        // this.lang = this.cookies.get(LANGUAGE)
    },
    methods: {
        onChange(langChange) {
            store.dispatch('setCountryChange', langChange)
            localStorage.setItem(LANGUAGE, langChange)
            // this.cookies.set(LANGUAGE, langChange)
            commons.setStoreLanguage(langChange)
            this._lenguage = langChange
            this.$i18n.locale = langChange
            this.lang = langChange
            // serviceData.init()
        }
    }
}
</script>

<style>
</style>