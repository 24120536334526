<template>
    <v-select :items="items" item-title="name" label="User" variant="underlined">
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.raw.department"></v-list-item>
      </template>
    </v-select>
  </template>
  <script>
  export default {
    data: () => ({
      items: [
        {
          name: 'John',
          department: 'Marketing',
        },
        {
          name: 'Jane',
          department: 'Engineering',
        },
        {
          name: 'Joe',
          department: 'Sales',
        },
        {
          name: 'Janet',
          department: 'Engineering',
        },
        {
          name: 'Jake',
          department: 'Marketing',
        },
        {
          name: 'Jack',
          department: 'Sales',
        },
      ],
    }),
  }
</script>