import { createStore } from 'vuex'
import commonStore from './common/index'
import userStore from './user/index'
import contracts from './contracts/index'
import buildStore from './building/index'
import themeStore from './theme/index'

export default createStore({
  modules: {
    commonStore,
    userStore,
    contracts,
    buildStore,
    themeStore
  }
})
