/** Common */
export const CLIENT_MESSAGE_ID = 'clentMessageId'
/** user */
export const USER = 'user'
export const USER_IP = 'local'
export const TOKEN_GOOGLE = 'TG'
export const LOAD_LIB_JS = 'loadJs'
export const STORE_LANGUAGE = 'languageSelected'
export const LOCALSSTORE_BUILD_ID = 'buildingId'
export const LOCALSSTORE_BUILD_NAME = 'buildingName'
export const USER_INFORM_LS = '100967563804809'
export const USER_FULL_NAME_LS = '811980409372729'
export const USER_STATUS_LS = '609144301700198'
export const USER_LOGON_LS = '191258517012726'
export const USER_NAME_LS = '797656315591283'
export const USER_EXPIRED_DATE_LS = '797656951594283'
export const SESIONID_INFORM_LS = '720970525757861'
export const OPT_INFORM_LS = '797656315594283'

/** end user */
export const TOKEN_INFORM_LS = '193935256188920'